const entities = {
  amp: "&",
  apos: "'",
  lt: "<",
  gt: ">",
  quot: '"',
  nbsp: "\xa0",
} as { [key: string]: string };
const entityPattern = /&([a-z]+);/gi;

export function fromHTML(html: string) {
  return html
    .replaceAll("<br />", "\n")
    .replaceAll("<br/>", "\n")
    .replaceAll("<br>", "\n")
    .replaceAll("<p>", "")
    .replaceAll("</p>", "\n")
    .replaceAll(entityPattern, function (match, entity) {
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }
      // return original string if there is no matching entity (no replace)
      return match;
    });
}

export function toHTML(text: string) {
  return text
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("\xa0", "&nbsp;")
    .split("\n")
    .map((line) => `<p>${line}</p>`)
    .join("\n");
}
