import { DocumentTextIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function EmptySectionContainer({
  addSection,
}: {
  addSection: () => void;
}) {
  return (
    <div className="text-left py-6">
      <div className="flex flex-row justify-start">
        <DocumentTextIcon className="w-12 h-12 text-gray-300" />
      </div>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No survey sections
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by generating an initial survey outline or by adding
        sections manually.
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-sky-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800"
          onClick={addSection}
          aria-label="Add Section"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add Section
        </button>
      </div>
    </div>
  );
}
