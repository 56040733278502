import { ReactElement, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CenteredSpinner } from "../Spinner";
import TopNav from "../../features/nav/TopNav";
import config from "../../config";
interface AuthPageProps {
  children?: ReactElement | Array<ReactElement>;
  title?: string;
  showNav?: boolean;
  flexCol?: boolean;
  constrainToViewportHeight?: boolean;
}

export default function AuthPage({
  children,
  title,
  showNav = true,
  constrainToViewportHeight = false,
  flexCol = true,
}: AuthPageProps) {
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.auth.isAuthPending);

  const navigate = useNavigate();
  useEffect(() => {
    if (title) document.title = title;
    else document.title = "Quest";
  }, [title]);
  useEffect(() => {
    handleRedirect();
  }, [user, isLoading]);

  async function handleRedirect() {
    if (isLoading) return;
    if (!user) {
      navigate("/drafter/login");
    }
  }
  if (!user) return <CenteredSpinner />;
  return (
    <div
      className={`${flexCol ? "flex-1 flex flex-col" : "flex-1"} ${
        constrainToViewportHeight ? "max-h-screen" : ""
      }`}
    >
      {showNav ? <TopNav user={user} /> : null}
      <Suspense fallback={<CenteredSpinner />}>
        <div className={flexCol ? "flex-1 flex flex-col overflow-auto" : ""}>
          {children}
        </div>
      </Suspense>
    </div>
  );
}
