export function pipeStream(
  url: string,
  body: any,
  cb: (text: string | null, end?: boolean) => void
) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok && response.body) {
          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();
          reader.read().then(function pump({ done, value }): any {
            if (value) cb(value, done);
            if (done) {
              cb(null, true);
              resolve(null);
              return;
            }
            return reader.read().then(pump);
          });
        } else {
          reject(new Error("Response not ok."));
        }
      })
      .catch((err) => reject(err));
  });
}
