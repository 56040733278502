import { useState } from "react";
import { parseSectionCompletion } from "../utils/parsers";
import { createSectionCompletion } from "../../../completion/completionAPI";

export default function useGeneration() {
  const [generationsBySectionId, setGenerationsBySectionId] = useState<{
    [key: string]: boolean;
  }>({});
  const [queue, setQueue] = useState<{ sectionId: string }[]>([]);
  function setSectionGenerating(sectionId: string, value: boolean) {
    setGenerationsBySectionId((prev) => ({ ...prev, [sectionId]: value }));
  }
  function isSectionGenerating(sectionId: string) {
    return !!generationsBySectionId[sectionId];
  }

  function generateSection(
    data: { surveyId: string; sectionId: string },
    cb: (questions: string[], done?: boolean) => void
  ) {
    let all = "";
    const { surveyId, sectionId } = data;
    createSectionCompletion({ surveyId, sectionId }, (text, done) => {
      all += text || "";
      const questions = parseSectionCompletion(all);
      cb(questions, done);
    });
  }

  return {
    isSectionGenerating,
    setSectionGenerating,
    isAnySectionGenerating: () =>
      Object.values(generationsBySectionId).some(Boolean),
    queue,
    pushToQueue: (sectionId: string) => {
      setQueue((prev) => [...prev, { sectionId }]);
    },
    pullFromQueue: () => {
      return queue.shift();
    },
    generateSection,
  };
}
