import config from "../config";
import axios from "axios";

export async function trackEvent(eventName: string, data?: any) {
  try {
    await axios.post(
      `${config.plucky.url}/batch`,
      {
        data: [
          {
            type: "event",
            event: {
              name: eventName,
              ...data,
            },
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${config.plucky.publicApiKey}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
}

export function identifyUser(
  userId: string,
  data?: { email?: string; name?: string; incQueryId?: string }
) {
  const userData = data || {};
}
