import Step from "./Step";

export default function StepSet({
  steps,
  startNumber,
  isGenerating = false,
  onDelete,
  onAdd,
  onGenerate,
  onStepChange,
  moveCursorTo,
  registerCursorUpdate,
  onLengthChange,
  requestedCursorPosition,
}: {
  steps: any[];
  startNumber: number;
  isGenerating: boolean;
  onDelete: (id: string) => void;
  onAdd: (i: number, content?: string) => void;
  onGenerate: () => void;
  onStepChange: (id: string, text: string) => void;
  moveCursorTo: (id: string, pos?: number) => void;
  registerCursorUpdate: () => void;
  onLengthChange: (stepId: string, length: number) => void;
  requestedCursorPosition: { id: string; pos: number } | null;
}) {
  return (
    <div>
      <div className="py-1">
        {steps.map((step, q) => (
          <Step
            key={step.id}
            i={q}
            number={startNumber + q}
            onChange={(text) => onStepChange(step.id, text)}
            text={step.text}
            onDelete={() => onDelete(step.id)}
            onAdd={(i, content) => onAdd(i === undefined ? q + 1 : i, content)}
            registerCursorUpdate={registerCursorUpdate}
            onLengthChange={(length) => onLengthChange(step.id, length)}
            requestedCursorPosition={
              requestedCursorPosition && requestedCursorPosition.id === step.id
                ? requestedCursorPosition.pos
                : null
            }
          />
        ))}
      </div>
    </div>
  );
}
