import { Dispatch, SetStateAction, useState } from "react";
import { SurveyData } from "../../../survey.types";
import PlanEditor from "../../PlanEditor";

export default function WizardPlanForm({
  survey,
  setSurvey,
  showNext,
  onSubmit,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  showNext?: boolean;
  onSubmit: () => void;
}) {
  const [confirmed, setConfirmed] = useState(false);
  return (
    <div className="pb-4 flex-1 pr-6">
      <div className="text-sm text-gray-600 mb-4 italic">
        Manually edit plan steps or update your survey description and
        regenerate results.
      </div>
      <div className="">
        <div className="w-full mb-4">
          <PlanEditor setSurvey={setSurvey} survey={survey} />
        </div>

        {showNext ? (
          <div>
            <div className="flex flex-row">
              <div className="pr-3">
                <input
                  type="checkbox"
                  id="confirm-plan"
                  aria-label="Confirm plan"
                  checked={confirmed}
                  onChange={(e) => setConfirmed(e.target.checked)}
                />
              </div>
              <label
                className="flex-1 text-gray-500 text-sm italic pt-0.5"
                htmlFor="confirm-plan"
              >
                I've updated the above plan to include all the topics my team
                and I would like covered.
              </label>
            </div>
            <div className="flex flex-row justify-end">
              <button
                disabled={!confirmed}
                onClick={onSubmit}
                className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
