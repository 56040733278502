import { useRef, useState } from "react";
import {
  ArrowDownTrayIcon,
  ChartBarSquareIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { saveFileFromBlob } from "../../../../utils/download";
import { download } from "../../surveyAPI";
import { handleError } from "../../../errors/errorUtils";
import { DebugPanel } from "../../../completionSession/DebugPanel";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { CenteredSpinner } from "../../../../components/Spinner";
import ReadyForProgrammingDialog from "./wizard/ReadyForProgrammingDialog";
import { CheckIcon } from "@heroicons/react/20/solid";

export function SurveyWorkspaceHeader({
  surveyId,
  title,
  height,
  setTitle,
  isDebugAvailable,
  showSubmit,
  setSurvey,
  isSubmitted,
}: {
  surveyId: string;
  setTitle(title: string): void;
  title: string;
  height: number;
  isDebugAvailable: boolean;
  showSubmit: boolean;
  setSurvey: (survey: any) => void;
  isSubmitted: boolean;
}) {
  const [showDebug, setShowDebug] = useState(false);
  const [showReadyForProgrammingDialog, setShowReadyForProgrammingDialog] =
    useState(false);
  const titleRef = useRef<HTMLInputElement>(null);
  function handleSubmit(e: React.FormEvent) {
    e.stopPropagation();
    titleRef.current?.blur();
  }

  return (
    <div style={{ height }} className="border-b flex flex-row">
      <div className="flex flex-row justify-content items-center pl-2">
        <Link
          to="/drafter/surveys"
          className="rounded text-gray-500 flex flex-row justify-center items-center p-1 hover:bg-gray-200"
        >
          <DocumentTextIcon className="w-5 h-5" />
        </Link>
      </div>
      <form
        onSubmit={handleSubmit}
        className="h-full flex flex-col justify-center flex-1"
      >
        <input
          type="text"
          name="title"
          value={title}
          ref={titleRef}
          style={{ minWidth: 400, width: 600 }}
          className="rounded inline-block hover:bg-gray-100 outline-none border-none bg-transparent font-medium text-sm px-2 py-1 placeholder:text-gray-400 focus:ring-none ring-none"
          placeholder="Untitled Survey"
          onChange={(e) => setTitle(e.target.value)}
        />
      </form>
      <div className="flex flex-row items-center pr-12 space-x-2">
        {isDebugAvailable ? (
          <button
            className="w-7 h-7 flex flex-row justify-center items-center hover:bg-gray-200 rounded"
            onClick={() => setShowDebug(true)}
          >
            <ChartBarSquareIcon className="w-5 h-5 text-gray-700" />
          </button>
        ) : null}

        <DownloadButton surveyId={surveyId} />

        {showSubmit ? (
          <SurveySubmitButton
            setOpen={setShowReadyForProgrammingDialog}
            isSubmitted={isSubmitted}
          />
        ) : null}
      </div>
      {isDebugAvailable ? (
        <DebugPanel
          show={showDebug}
          setShow={setShowDebug}
          surveyId={surveyId}
        />
      ) : null}
      <ReadyForProgrammingDialog
        open={showReadyForProgrammingDialog}
        setOpen={setShowReadyForProgrammingDialog}
        setSurvey={setSurvey}
        surveyId={surveyId}
      />
    </div>
  );
}

function DownloadButton({ surveyId }: { surveyId: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip(),
      shift({ padding: 8 }),
      offset({ mainAxis: -66, alignmentAxis: 32 }),
    ],
    placement: "left-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  async function handleDownloadClick(type: "docx" | "txt" | "json") {
    try {
      if (submitting) return;
      setSubmitting(true);
      setIsOpen(false);
      const { data, headers } = await download(surveyId, type);
      saveFileFromBlob(
        data,
        headers["content-disposition"].replaceAll("attachment; filename=", "")
      );
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  const width = 100;
  return (
    <>
      {submitting ? (
        <div style={{ width, paddingTop: 5 }}>
          <CenteredSpinner size="sm" />
        </div>
      ) : (
        <button
          className="text-sm hover:bg-gray-100 rounded py-2 px-4"
          style={{ width }}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          Export
          <ChevronDownIcon className="w-4 h-4 inline-block ml-1 relative -top-0.5" />
        </button>
      )}
      {isOpen && !submitting && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{ ...floatingStyles, width }}
          className="bg-white rounded border border-gray-200 shadow-sm z-50"
        >
          <div
            className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDownloadClick("docx")}
          >
            .docx
          </div>
          <div
            className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDownloadClick("txt")}
          >
            .txt
          </div>
          <div
            className="px-4 py-1.5 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDownloadClick("json")}
          >
            .json
          </div>
        </div>
      )}
    </>
  );
}
function SurveySubmitButton({
  isSubmitted,
  setOpen,
}: {
  isSubmitted: boolean;
  setOpen: (open: boolean) => void;
}) {
  if (isSubmitted)
    return (
      <div className="flex flex-row items-center text-sm text-sky-700 font-medium">
        <CheckIcon className="w-4 h-4 relative mr-1.5" />
        Submitted
      </div>
    );
  return (
    <button
      disabled={false}
      onClick={() => setOpen(true)}
      className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
    >
      Next
    </button>
  );
}
