const stepLineRegex = /^(\s*-\s*)/;
const questionLineRegex = /^Q(\d+\. )/;
const sectionLineRegex = /^(\d+\. )/;

interface ParsedSectionData {
  title: string;
  items: string[];
}

export function parseSectionCompletion(text: string): string[] {
  return text
    .replace(/^\s+|\s+$/g, "")
    .split("\n")
    .reduce((acc, line) => {
      if (acc.length === 0 || line.match(questionLineRegex)) {
        acc.push(line.replace(questionLineRegex, ""));
      } else {
        if (acc[acc.length - 1].length > 0) {
          acc[acc.length - 1] += "\n";
        }
        acc[acc.length - 1] += line;
      }
      return acc;
    }, [] as string[])
    .map((question) => question.replaceAll(/^\n+|\n+$/g, ""));
}

export function parseOutlineCompletion(text: string): ParsedSectionData[] {
  return text
    .split("\n")
    .reduce((acc, line) => {
      const lastSection = acc[acc.length - 1];

      if (acc.length === 0 || line.match(sectionLineRegex)) {
        acc.push({
          title:
            acc.length === 0 ? "Screener" : line.replace(sectionLineRegex, ""),
          items: [],
        });
      } else if (line.match(stepLineRegex)) {
        lastSection.items.push(line.replace(stepLineRegex, ""));
      } else {
        if (lastSection.items[lastSection.items.length - 1]?.length > 0) {
          lastSection.items[lastSection.items.length - 1] += "\n";
        }
        lastSection.items[lastSection.items.length - 1] += line;
      }
      return acc;
    }, [] as ParsedSectionData[])
    .map((section) => {
      return {
        ...section,
        items: section.items.map((q) => q.replace(/^\n+|\n+$/g, "")), // Trim excess newlines
      };
    });
}
