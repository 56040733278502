import styles from "./Questions.module.css";
import EditableDiv from "./EditableDiv";
import editorStyles from "../SurveyEditor.module.css";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon, Bars4Icon } from "@heroicons/react/24/outline";
import { forwardRef, useEffect, useRef, useState } from "react";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";

import invariant from "tiny-invariant";
import RegenerationOptions from "./RegenerationOptions";
import {
  useFloating,
  autoUpdate,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import { createQuestionCompletion } from "../../../completion/completionAPI";
import { fromHTML, toHTML } from "../../../../../../shared/utils/html";
import DropdownOptions from "./DropdownOptions";
import { trackEvent } from "../../../../utils/tracking";
import { useSurveyContext } from "../surveyContext";
export default function Question({
  id,
  i,
  number,
  text,
  previousText,
  accepted,
  onChange,
  onDelete,
  onAdd,
  onDrop,
  setDragId,
  disabled = false,
  requestedCursorPosition,
  registerCursorUpdate,
  onLengthChange,
}: {
  id: string;
  i: number;
  number: number;
  text: string;
  previousText: string;
  accepted: boolean;
  onChange: (
    text: string,
    options?: {
      accepted?: boolean;
      previousText?: string;
    }
  ) => void;
  onDelete: () => void;
  onAdd: (i: number) => void;
  onDrop: () => void;
  collapsed?: boolean;
  setDragId: (questionId: string | null) => void;
  disabled?: boolean;
  registerCursorUpdate: () => void;
  requestedCursorPosition: number | null;
  onLengthChange: (length: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const handleRef = useRef(null);
  const [showRegenerationOptions, setShowRegenerationOptions] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "left-start",
    open: showRegenerationOptions,
    onOpenChange: setShowRegenerationOptions,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const [dragging, setDragging] = useState(false);
  const { survey } = useSurveyContext();

  function handleRegenerationClick(value: string) {
    let all = "";
    console.log("regenerating", value);
    const previousText = accepted ? text : "";
    createQuestionCompletion(
      { questionId: id, change: value },
      (chunk, done) => {
        all += chunk || "";
        onChange(toHTML(all), {
          accepted: false,
          previousText,
        });
      }
    );
  }

  useEffect(() => {
    const el = ref.current;
    const handleEl = handleRef.current;

    invariant(handleEl);

    invariant(el);
    return draggable({
      element: el,
      dragHandle: handleEl,
      onDragStart: () => {
        setDragging(true);
        setDragId(id);
      },
      onDrop() {
        setDragging(false);
        onDrop();
      },
    });
  }, [id, onDrop, setDragId]);

  return (
    <div
      className={`${styles.question} ${dragging ? "opacity-20" : ""} relative`}
    >
      <div
        className={`flex flex-row items-start relative ${editorStyles["padded-container"]}`}
      >
        <div ref={ref}>
          <button
            aria-label={`Drag handle for Q${number}`}
            className={`text-gray-400 hover:text-gray-800 p-1 rounded absolute ${styles["hidden-button"]}`}
            style={{ left: -40, top: 7 }}
            ref={handleRef}
          >
            <Bars4Icon className="w-5 h-5" />
          </button>
          {accepted ? (
            <div>
              <div className="pr-2 w-12" style={{ paddingTop: 9 }}>
                Q{number}.
              </div>
              <div
                className={`mt-2 ${
                  showRegenerationOptions ? "" : styles["hidden-button"]
                }`}
              >
                <RegenerateButton
                  {...getReferenceProps()}
                  ref={refs.setReference}
                  onClick={() => setShowRegenerationOptions(true)}
                />
              </div>
            </div>
          ) : (
            <div className="pr-2 w-12 flex flex-col" style={{ paddingTop: 6 }}>
              <div>
                <button
                  className="inline-block p-1.5 -ml-1.5 hover:bg-emerald-100 rounded-lg mb-1 text-emerald-600 hover:text-emerald-700"
                  onClick={() => {
                    trackEvent("accepted a suggestion", {
                      type: "accept_suggestion",
                      details: `${
                        fromHTML(text)
                          .split("\n")
                          .filter((a) => a)[0]
                      }`,
                      caseExternalId: survey.id,
                    });
                    onChange(text, {
                      accepted: true,
                    });
                  }}
                >
                  <CheckIcon className="w-4 h-4  " />
                </button>
              </div>
              <div>
                <button
                  className="inline-block p-1.5 -ml-1.5 hover:bg-red-100 rounded-lg mb-1 text-red-400 hover:text-red-600"
                  onClick={() => {
                    trackEvent("rejected a suggestion", {
                      type: "reject_suggestion",
                      details: `${
                        fromHTML(text)
                          .split("\n")
                          .filter((a) => a)[0]
                      }`,
                      caseExternalId: survey.id,
                    });
                    if (previousText) {
                      onChange(previousText, {
                        accepted: true,
                        previousText: "",
                      });
                    } else {
                      onDelete();
                    }
                  }}
                >
                  <XMarkIcon className="w-4 h-4 " />
                </button>
              </div>
              <div>
                <RegenerateButton
                  {...getReferenceProps()}
                  ref={refs.setReference}
                  onClick={() =>
                    setShowRegenerationOptions(!showRegenerationOptions)
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className={`flex-1 pr-4 ${disabled ? "pointer-events-none" : ""}`}>
          <EditableDiv
            value={text}
            ariaLabel="Question Text"
            onChange={(value) => onChange(value)}
            onBackspaceAtStart={() => {
              if (text.length) return;
              onDelete();
            }}
            className={`transition-colors transition-duration-500 h-full w-full h-min-36 block rounded-md border-0 py-2 px-3 text-gray-900 ${
              accepted
                ? "hover:bg-gray-100 hover:shadow-sm"
                : "underline text-red-700/80"
            } placeholder:text-gray-400`}
            disabled={!accepted || dragging || disabled}
            registerCursorUpdate={registerCursorUpdate}
            requestedCursorPosition={requestedCursorPosition}
            onLengthChange={onLengthChange}
          />
        </div>
        <div>
          <DropdownOptions
            options={[
              {
                name: "Add Above",
                onClick: () => onAdd(i),
              },
              {
                name: "Add Below",
                onClick: () => onAdd(i + 1),
              },
              {
                name: "Remove",
                onClick: () => onDelete(),
              },
            ]}
            buttonClassName={styles["hidden-button"]}
          />
        </div>
      </div>
      <RegenerationOptions
        ref={refs.setFloating}
        style={floatingStyles}
        show={showRegenerationOptions}
        onClick={(value: string) => {
          handleRegenerationClick(value);
          setShowRegenerationOptions(false);
        }}
        {...getFloatingProps()}
      />
    </div>
  );
}

const RegenerateButton = forwardRef<HTMLButtonElement, { onClick: () => void }>(
  ({ onClick }, ref) => {
    return (
      <button
        className="inline-block p-1.5 -ml-1.5 hover:bg-gray-100 rounded-lg mb-1 text-gray-400 hover:text-gray-700"
        ref={ref}
        onClick={onClick}
      >
        <ArrowPathIcon className="w-4 h-4 " />
      </button>
    );
  }
);
