import { Dispatch, Fragment, SetStateAction, useRef } from "react";
import { useSurveySetters } from "./hooks/useSurvey";
import { trackEvent } from "../../../utils/tracking";
import useGeneration from "./hooks/useGeneration";
import Section from "./components/Section";
import EmptySectionContainer from "./components/EmptySectionContainer";
import { useRecord } from "./hooks/useRecord";
import styles from "./SurveyEditor.module.css";
import { SurveyData } from "../survey.types";
import StepSet from "./components/StepSet";
import useCursor from "./hooks/useCursor";

export default function PlanEditor({
  survey,
  setSurvey,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
}) {
  const {
    addSection,
    setSection,
    removeSection,
    addStep,
    removeStep,
    sectionStepStartingNumbers,
    setStep,
  } = useSurveySetters(survey, setSurvey);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [setSectionCollapse, isSectionCollapsed] = useRecord();
  const {
    registerCursorUpdate,
    requestedCursorPosition,
    moveCursorTo,
    registerInputLength,
  } = useCursor();

  const { isSectionGenerating } = useGeneration();

  function handleSectionDelete(s: number) {
    const section = survey.sections[s];
    if (
      section.title.length ||
      section.objectives.length ||
      section.questions.length
    ) {
      const confirm = window.confirm(
        "Are you sure you want to delete this section?"
      );
      if (!confirm) return;
    }
    trackEvent("section_delete", {
      survey_id: survey.id,
    });
    removeSection(s);
  }

  function handleRemoveStep(sectionId: string, id: string) {
    const section = survey.sections.find((s) => s.id === sectionId);
    if (!section)
      throw new Error("Could not find section with id " + sectionId);
    const step = section.steps.find((q) => q.id === id);
    if (!step) throw new Error("Could not find step with id " + id);
    if (step.text.length > 0) {
      const confirm = window.confirm(
        "Are you sure you want to delete this question?"
      );
      if (!confirm) return;
    }
    removeStep(sectionId, id);
  }

  return (
    <Fragment>
      <div ref={sectionRef} aria-label="Plan Editor">
        {survey.sections.length > 0 ? (
          survey.sections.map((section: any, s: number) => (
            <Section
              i={s + 1}
              key={section.id}
              section={section}
              onChange={(section: any) => setSection(s, section)}
              onDeleteClick={() => handleSectionDelete(s)}
              onAddClick={() => addSection(s + 1)}
              onAddQuestionClick={() =>
                addStep(section.id, section.steps.length)
              }
              setCollapsed={(value) => setSectionCollapse(section.id, value)}
              isCollapsed={isSectionCollapsed(section.id)}
              handleSubmit={() => addStep(section.id, 0)}
              isScreener={
                section.title.toLowerCase().includes("screen") && s === 0
              }
            >
              <StepSet
                steps={section.steps}
                startNumber={sectionStepStartingNumbers[s]}
                onStepChange={(id: string, text: string) =>
                  setStep(section.id, id, text)
                }
                onDelete={(stepId) => handleRemoveStep(section.id, stepId)}
                onAdd={(i: number, content?: string) => {
                  const stepId = addStep(section.id, i, content);
                  moveCursorTo(stepId, 0);
                }}
                onGenerate={() => {}}
                isGenerating={isSectionGenerating(section.id)}
                onLengthChange={(stepId: string, length: number) =>
                  registerInputLength(stepId, length)
                }
                requestedCursorPosition={requestedCursorPosition}
                registerCursorUpdate={registerCursorUpdate}
                moveCursorTo={moveCursorTo}
              />
            </Section>
          ))
        ) : (
          <div className={`py-8 ${styles["padded-container"]}`}>
            <EmptySectionContainer addSection={() => addSection(0)} />
          </div>
        )}
      </div>
    </Fragment>
  );
}
