export const exampleDescriptions: {
  description: string;
  respondentType: string;
}[] = [
  {
    description: `I want to understand the market for braking systems, where typical customers are vehicle manufacturers like Ford, Nissan, Toyota. Competitors in the space are Brembo and Zimmermann. I particularly want to focus on brand perceptions and purchasing considerations.`,
    respondentType: "b2b",
  },

  {
    respondentType: "b2b",
    description: `Screeners
-	Firmographics
-	Employment status, company revenue, number of employees, industry, 
-	Decisionmaking responsibilities 
-	Which of the following vendors are you aware of?
-	Which of the following vendors does your company use?
-	Confirm whether vendors are used through free or paid license
-	For which vendors were you heavily involved in the purchasing / re-evaluation process? 

[Qualify customers + decision makers for relevant 

Exit-loop 
-	[If not using P1 vendors] How likely to adopt vendors in N3Y? Why?
-	If highly likely, which existing vendors would be displaced? (or adopting for new workflows)
-	If highly likely, ask for which use cases 

Overall competitive dynamics [loop 2x]
-	NPS [0-10], Why?
-	Year adopted
-	Which other vendor displaced and why
-	Vendor decisionmakers
-	Vendor users
-	Core use cases: Current vendor use cases
o	Perceived product quality by use case
-	Additional use cases: Vendor usage or interest in adopting over N3Y
-	
-	What is current spend on vendor
-	What was spend change on vendor p.a. over L3Y
-	Expected spend change on vendor p.a. over N3Y
-	If Increase:
-	Reason for spend change
-	If Decline: 
-	Reason for spend change
-	Difficulty of switching away
-	Likelihood to switch away
-	Which vendor likely to switch to
-	What types of likely to switch (more/less mission critical)

Decision dynamics:
-	Reason for multi-sourcing
-	Have you consolidation to fewer vendors in L3Y? Which vendors dropped?
-	Expectation to consolidate to fewer vendors in N3Y? Which ones dropping?

Spend:
-	Total category spend
-	Category spend change p.a. over N3Y
-	% workloads today vs N3Y
-	Total category spend
-	Segment  spend p.a. over N3Y
-	Allocate share to vendors (L3Y, today, N3Y)`,
  },

  {
    respondentType: "b2c",
    description: `Survey topic: Spices in supermarkets e.g. garlic, cinnamon, chili powder, salt, pepper, paprika.

Screeners
- US census demographics for balancing
- consumer activities done (including shopping in supermarket, and cooking)
- responsible for household shopping
- types of cooking done
- types of foods purchased including condiments and spices


Awareness funnel of spice brands
- types of spice matrix across brands.

Overall competitive dynamics [loop 2x]

NPS [0-10], Why?

KPCs

User behaviour

buying frequency

package sizes bought
`,
  },

  {
    respondentType: "b2c",
    description: `Evaluate the growth potential of Melissa & Doug, an early childhood toy brand. Understand parents' purchasing habits and criteria for their infant, toddler, and preschool children's toys, including their purchase criteria, the types of toys they favor, and which channels they shop at. Measure customer advocacy for various competitive brands.


1. Screeners

- Initial demos

- Ages of children

- Must have purchased toys in L12M

- Who have they purchased toys for? [Aim for 80% own children, 20% gifts]

- Awareness funnel [Quota N=100 on M&D]

2. NPS + KPCs

3. Purchase behavior

- Frequency of purchases and total annual spend

- New product discovery sources

4. Channels

- Share of wallet by channel

- Share of wallet for online by store

- Brand-driven vs. channel-driven?

- Showrooming

5. M&D deep dive

- Child engagement

- Offering quality by age

6. Segmentation

- Psychographics  [/demo/technographics/firmographics]

7. Demographics
`,
  },
];
