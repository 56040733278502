import { FloatingPortal } from "@floating-ui/react";
import { CSSProperties, FormEvent, forwardRef, useState } from "react";
interface RegenerationOptionsProps {
  style?: CSSProperties;
  show: boolean;
  onClick: (value: string) => void;
}
const RegenerationOptions = forwardRef<
  HTMLDivElement,
  RegenerationOptionsProps
>(({ style, show, onClick }, ref) => {
  const [customValue, setCustomValue] = useState("");
  if (!show) return null;
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    onClick(customValue);
    setCustomValue("");
  }
  return (
    <FloatingPortal>
      <div
        className={`px-2 ${show ? "" : "hidden"}`}
        ref={ref}
        style={{ ...style, width: 300 }}
      >
        <div className="shadow-lg rounded divide-y text-sm bg-white">
          <form onSubmit={handleSubmit}>
            <input
              autoFocus
              value={customValue}
              onChange={(e) => setCustomValue(e.target.value)}
              className="rounded-t placeholder:text-sm text-sm placeholder:text-gray-400 w-full active:ring-0 focus:ring-0 focus:border border border-sky-800"
              placeholder="What would you like to change?"
            />
          </form>
          <button
            className="py-2.5 px-4 hover:bg-gray-100 block w-full text-left border-x"
            onClick={() => onClick("moreAnswers")}
          >
            More choices
          </button>
          <button
            className="py-2.5 px-4 hover:bg-gray-100 block w-full text-left border-x"
            onClick={() => onClick("fewerAnswers")}
          >
            Fewer choices
          </button>
          <button
            className="py-2.5 px-4 hover:bg-gray-100 block w-full text-left border-x border-b rounded-b"
            onClick={() => onClick("lessLeading")}
          >
            Make less leading
          </button>
        </div>
      </div>
    </FloatingPortal>
  );
});

export default RegenerationOptions;
