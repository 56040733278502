import { pipeStream } from "../../utils/stream";

export async function createSectionCompletion(
  data: { surveyId: string; sectionId: string },
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/section`, data, cb);
}

export async function createPlanCompletion(
  body: { description: string; surveyId: string },
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/plan`, body, cb);
}

export async function createTitleCompletion(
  description: string,
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/title`, { description }, cb);
}
export async function createQuestionCompletion(
  body: {
    questionId: string;
    change: string;
  },
  cb: (text: string | null, done?: boolean) => void
) {
  return pipeStream(`/drafter/api/completions/question`, body, cb);
}
