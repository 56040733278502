import { useState } from "react";
import ReadyForProgrammingDialog from "./ReadyForProgrammingDialog";
import { CheckIcon } from "@heroicons/react/20/solid";

export default function WizardReadyForProgrammingForm({
  isSubmitted = false,
  setSurvey,
  surveyId,
  incQueryUserId,
}: {
  isSubmitted?: boolean;
  setSurvey: (survey: any) => void;
  surveyId: string;
  incQueryUserId: string;
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="pr-6">
      <div className="text-sm text-gray-500 mb-4">{`When ready, click the button below to submit your draft to a IncQuery Survey Director to begin the programming process.`}</div>
      <div className="flex flex-row justify-end">
        <div className="flex-1 flex flex-row items-end pb-1">
          <a
            href={`https://iq-dist-2.com/s/start/en-us/EGbGaUS5Q1miVvO3x1yy2g/S6efc4nFRfGt08kCB1g5mQ?survey_ID=${surveyId}&user_ID=${incQueryUserId}&nosplash=true`}
            className="text-xs underline hover:underline text-sky-800 hover:cursor-pointer"
            target="_blank"
          >
            Give Feedback
          </a>
        </div>
        {isSubmitted ? (
          <div className="flex flex-row items-center text-sm text-sky-700 font-medium">
            <CheckIcon className="w-4 h-4 relative mr-1.5" />
            Submitted
          </div>
        ) : (
          <button
            disabled={false}
            onClick={() => setOpen(true)}
            className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
          >
            Next
          </button>
        )}
      </div>
      <ReadyForProgrammingDialog
        open={open}
        setOpen={setOpen}
        surveyId={surveyId}
        setSurvey={setSurvey}
      />
    </div>
  );
}
