import { Dispatch, SetStateAction, useState } from "react";
import WizardDescriptionForm from "./WizardDescriptionForm";

import {
  createPlanCompletion,
  createTitleCompletion,
} from "../../../../completion/completionAPI";
import { fromHTML, toHTML } from "../../../../../../../shared/utils/html";
import { SurveyData } from "../../../survey.types";
import { useSurveySetters } from "../../hooks/useSurvey";
import WizardPlanForm from "./WizardPlanForm";
import { motion } from "framer-motion";
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronDownIcon,
  LightBulbIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { parseOutlineCompletion } from "../../utils/parsers";
import { uuidv4 } from "../../../../../utils/uuid";
import WizardReadyForProgrammingForm from "./WizardReadyForProgrammingForm";
import { useAppSelector } from "../../../../../app/hooks";

const DESCRIPTION_STEP = "description";
const OUTLINE_STEP = "outline";
const PROGRAMMING_STEP = "programming";

export default function Wizard({
  survey,
  setSurvey,
  onGenerateSection,
}: {
  survey: SurveyData;
  setSurvey: Dispatch<SetStateAction<SurveyData>>;
  onGenerateSection: (sectionId: string) => void;
}) {
  const steps = [DESCRIPTION_STEP, OUTLINE_STEP, PROGRAMMING_STEP];
  const { setObjectives, questionCount, stepCount, setRespondentType } =
    useSurveySetters(survey, setSurvey);

  const user = useAppSelector((state) => state.auth.user);
  const [descriptionOpen, setDescriptionOpen] = useState(
    survey.sections.length <= 1
  );
  const [outlineOpen, setOutlineOpen] = useState(survey.sections.length > 1);
  const [readyForProgrammingOpen, setReadyForProgrammingOpen] = useState(
    survey.sections.length > 1
  );
  const [generating, setGenerating] = useState(false);

  function handleTitleGeneration() {
    if (survey.title) return;
    let newTitle = "";
    createTitleCompletion(survey.objectives, (text) => {
      newTitle += text || "";
      setSurvey((survey) => ({ ...survey, title: newTitle }));
    });
  }

  function generatePlan(description: string) {
    if (generating) return;
    try {
      if (stepCount > 0) {
        const confirm = window.confirm(
          "Are you sure you want to regenerate your plan? This will overwrite any changes you've made."
        );
        if (!confirm) return;
      }
      let planText = "";
      setGenerating(true);
      createPlanCompletion(
        { description: fromHTML(description), surveyId: survey.id },
        (text, done) => {
          planText += text || "";
          if (done) {
            setGenerating(false);
            console.log("Plan text: ", planText);
          }
          const parsedSections = parseOutlineCompletion(planText);
          setSurvey((survey: SurveyData) => {
            return {
              ...survey,
              sections: parsedSections.map((section, s) => ({
                id: survey.sections[s]?.id || uuidv4(),
                title: section.title,
                objectives: "",
                questions: survey.sections[s]?.questions || [],
                steps: section.items.map((step, st) => ({
                  id: survey.sections[s]?.steps[st]?.id || uuidv4(),
                  text: toHTML(step),
                })),
              })),
            };
          });
        }
      );
    } catch (err) {
      console.error(err);
      setGenerating(false);
    }
  }

  const currentStep = calculateCurrentStep();

  function calculateCurrentStep() {
    if (survey.sections.length > 1) return 2;
    if (survey.plan.length > 0) return 1;
    return 0;
  }

  return (
    <div>
      <WizardSection
        icon={<LightBulbIcon className="w-4" />}
        title="Description"
        open={descriptionOpen}
        setOpen={setDescriptionOpen}
        step={0}
        currentStep={currentStep}
        totalSteps={steps.length}
      >
        <WizardDescriptionForm
          survey={survey}
          setObjectives={setObjectives}
          setRespondentType={setRespondentType}
          onSubmit={async () => {
            handleTitleGeneration();
            setOutlineOpen(true);
            setDescriptionOpen(false);
            generatePlan(survey.objectives);
          }}
          showNext={stepCount === 0}
        />
      </WizardSection>
      <WizardSection
        icon={<ListBulletIcon className="w-5" />}
        title="Plan"
        open={outlineOpen}
        setOpen={setOutlineOpen}
        onRegenerate={() => generatePlan(survey.objectives)}
        generating={generating}
        step={1}
        currentStep={currentStep}
        totalSteps={steps.length}
      >
        <WizardPlanForm
          survey={survey}
          setSurvey={setSurvey}
          showNext={!generating && questionCount <= 1}
          onSubmit={() => {
            onGenerateSection(survey.sections[0].id);
            setReadyForProgrammingOpen(true);
          }}
        />
      </WizardSection>
      <WizardSection
        icon={<CheckIcon className="w-4" />}
        title="Programming"
        open={readyForProgrammingOpen}
        setOpen={setReadyForProgrammingOpen}
        step={2}
        currentStep={currentStep}
        totalSteps={steps.length}
      >
        <WizardReadyForProgrammingForm
          isSubmitted={!!survey.submittedAt}
          surveyId={survey.id}
          incQueryUserId={user.incQueryId}
          setSurvey={setSurvey}
        />
      </WizardSection>
    </div>
  );
}

function WizardSection({
  title,
  open,
  setOpen,
  children,
  onRegenerate,
  generating,
  step,
  currentStep,
  totalSteps,
  icon,
}: {
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  children: React.ReactNode | React.ReactNode[];
  onRegenerate?: () => void;
  generating?: boolean;
  step: number;
  currentStep: number;
  totalSteps: number;
  icon: React.ReactNode;
}) {
  const priorStepActive = currentStep > step - 1;
  const isActive = currentStep >= step;
  const nextStepActive = currentStep > step;
  const isFirstStep = step === 0;
  const isLastStep = totalSteps === step + 1;
  return (
    <div className="relative">
      {!isFirstStep && (
        <div
          className={`absolute h-full -z-5 top-0 ${
            priorStepActive ? "bg-sky-600" : "bg-gray-200"
          }`}
          style={{
            left: 23,
            height: 16,
            width: 2,
          }}
        ></div>
      )}
      {!isLastStep && (
        <div
          className={`absolute h-full -z-5 ${
            nextStepActive
              ? "bg-sky-600"
              : priorStepActive
              ? "bg-gradient-to-b from-sky-600 to-gray-200"
              : "bg-gray-200"
          }`}
          style={{
            left: 23,
            top: 32,
            height: `calc(100% - 32px)`,
            width: 2,
          }}
        ></div>
      )}
      <div className="flex flex-row">
        <div className="p-2 h-16 flex flex-row items-center">
          <div
            className={`rounded-full ${
              isActive ? "bg-sky-600 text-white" : "bg-gray-200 text-gray-400"
            }  w-8 h-8 flex flex-row justify-center items-center z-5 relative`}
          >
            {icon}
          </div>
        </div>
        <div className={`${isLastStep ? "" : "border-b"} flex-1`}>
          <div className="flex flex-row items-center h-16">
            <div className="flex-1 px-2 font-medium">{title}</div>
            <div className="px-4 space-x-2">
              {onRegenerate && (
                <button
                  className="hover:bg-gray-100 rounded p-1.5"
                  onClick={onRegenerate}
                  disabled={generating}
                >
                  <motion.div
                    animate={generating ? "generating" : ""}
                    variants={{
                      generating: { rotate: [0, 360] },
                    }}
                    transition={{
                      duration: 1.2,
                      repeat: Infinity,
                      ease: "easeOut",
                    }}
                  >
                    <ArrowPathIcon className="w-4 h-4" />
                  </motion.div>
                </button>
              )}
              <button
                className="hover:bg-gray-100 rounded p-1.5"
                onClick={() => setOpen(!open)}
              >
                <motion.div
                  animate={open ? "open" : "closed"}
                  variants={{
                    open: { rotate: 0 },
                    closed: { rotate: -90 },
                  }}
                  initial={false}
                >
                  <ChevronDownIcon className="w-4 h-4" />
                </motion.div>
              </button>
            </div>
          </div>
          <div className={`${open ? "" : "hidden"}`}>{children}</div>
        </div>
      </div>
    </div>
  );
}
