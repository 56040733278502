import styles from "./Questions.module.css";
import EditableDiv from "./EditableDiv";
import editorStyles from "../SurveyEditor.module.css";

import DropdownOptions from "./DropdownOptions";

export default function Step({
  number,
  i,
  text,
  onChange,
  onDelete,
  onAdd,
  registerCursorUpdate,
  onLengthChange,
  requestedCursorPosition,
}: {
  number: number;
  i: number;
  text: string;
  onChange: (text: string) => void;
  onDelete: () => void;
  onAdd: (i?: number, content?: string) => void;
  registerCursorUpdate: () => void;
  onLengthChange: (length: number) => void;
  requestedCursorPosition: number | null;
}) {
  return (
    <div className={`${styles.question} py-0.5`}>
      <div
        className={`flex flex-row items-start relative ${editorStyles["padded-container"]}`}
      >
        <div className="pr-2 text-sm" style={{ paddingTop: 5 }}>
          •
        </div>
        <div className="flex-1 pr-4">
          <EditableDiv
            value={text}
            ariaLabel={`Step ${number}`}
            onChange={(value) => onChange(value)}
            onEnter={(_, after) => onAdd(undefined, after)}
            onBackspaceAtStart={onDelete}
            className={` text-smh-full w-full h-min-36 block rounded-md border-0 py-1 px-2 text-gray-900 hover:shadow-sm hover:bg-gray-100 placeholder:text-gray-400`}
            registerCursorUpdate={registerCursorUpdate}
            onLengthChange={onLengthChange}
            requestedCursorPosition={requestedCursorPosition}
          />
        </div>
        <div>
          <DropdownOptions
            buttonClassName={styles["hidden-button"]}
            options={[
              {
                name: "Add Above",
                onClick: () => onAdd(i),
              },
              {
                name: "Add Below",
                onClick: () => onAdd(i + 1),
              },
              {
                name: "Remove",
                onClick: () => onDelete(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
