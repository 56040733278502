import EditableDiv from "../EditableDiv";
import styles from "./Wizard.module.css";
import { toHTML } from "../../../../../../../shared/utils/html";
import { SurveyDraftData } from "../../../survey.types";
import SubmitButton from "../../../../../components/form/SubmitButton";
import { useState } from "react";
import { handleError } from "../../../../errors/errorUtils";
import { exampleDescriptions } from "../../../../../../../shared/utils/surveyExamples";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../../../components/Tooltip";

export default function WizardDescriptionForm({
  onSubmit,
  showNext,
  survey,
  setObjectives,
  setRespondentType,
}: {
  survey: SurveyDraftData;
  showNext?: boolean;
  setObjectives: (objectives: string) => void;
  setRespondentType: (respondentType: string) => void;
  onSubmit: () => Promise<void>;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [exampleCounter, setExampleCounter] = useState(0);
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      if (submitting || !survey.objectives) return;
      setSubmitting(true);

      await onSubmit();
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  function loadExample() {
    if (survey.objectives) {
      const confirm = window.confirm(
        "Are you sure you want to load an example? This will overwrite your current objectives."
      );
      if (!confirm) return;
    }
    const { respondentType, description } =
      exampleDescriptions[exampleCounter % exampleDescriptions.length];
    setExampleCounter((counter) => counter + 1);
    setObjectives(toHTML(description));
    setRespondentType(respondentType);
  }

  return (
    <div className="pb-4 flex-1 pr-6">
      <form onSubmit={handleSubmit}>
        <div className="">
          <div>
            <div className="w-full mb-4">
              <label
                className="block leading-6 text-sm text-gray-500"
                htmlFor="objectives"
              >
                What would you like to learn from this survey? Please include
                the country or geographic region relevant to your respondents.
                If you have a schematic or survey outline, add it below. If
                there's an existing survey that you'd like to replicate, copy
                and paste it below with instructions as to what you'd like to
                re-use or change from the existing survey.
              </label>
              <div className="mb-2 text-right">
                <button
                  type="button"
                  className="underline text-xs text-gray-400 mb-2"
                  onClick={loadExample}
                >
                  Need an example?
                </button>
              </div>
              <EditableDiv
                id="objectives"
                ariaLabel="Description"
                value={survey.objectives}
                onChange={setObjectives}
                autoFocus={survey.objectives.length === 0}
                className={`${styles["objectives-input"]} block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6`}
                placeholder="e.g. Understand the US athletic clothing market."
              />
            </div>
            <div className="flex flex-row">
              <div className="flex-1 flex flex-row">
                <div className="w-44">
                  <label
                    htmlFor="respondentType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Respondent type
                    <Tooltip>
                      <TooltipTrigger>
                        <QuestionMarkCircleIcon className="ml-2 w-4 h-4 inline-block" />
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-800 text-white text-xs px-4 py-2 rounded">
                        Internal surveys are not yet supported.
                      </TooltipContent>
                    </Tooltip>
                  </label>
                  <select
                    id="respondentType"
                    name="respondentType"
                    value={survey.respondentType || ""}
                    onChange={(e) => setRespondentType(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">--- Select one ---</option>
                    <option value="b2b">B2B</option>
                    <option value="b2c">B2C</option>
                  </select>
                </div>
              </div>
              {showNext ? (
                <div className="flex flex-row justify-end items-end w-60">
                  <SubmitButton
                    disabled={!survey.objectives || !survey.respondentType}
                    submitting={submitting}
                    className="items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                  >
                    Next
                  </SubmitButton>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
